<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">专项报告信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报告类型">
                    <el-select
                        v-model="formdata.saveJson.bglx"
                        filterable
                        clearable
                        :disabled="!isEdit"
                    >
                        <el-option label="考古报告" value="1"></el-option>
                        <el-option label="其他" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报告名称">
                    <el-input
                        v-model="formdata.saveJson.kgbgmc"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="编制单位">
                    <el-input
                        v-model="formdata.saveJson.bzdw"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="编写人员">
                    <el-input
                        v-model="formdata.saveJson.bxry"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="出版状态">
                    <el-select
                        v-model="formdata.saveJson.cbzt"
                        filterable
                        clearable
                        :disabled="!isEdit"
                    >
                        <el-option label="未出版" value="未出版"></el-option>
                        <el-option label="已出版" value="已出版"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出版时间">
                    <el-date-picker
                        v-model="formdata.saveJson.cbsj"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="出版单位">
                    <el-input
                        v-model="formdata.saveJson.cbdw"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    basicType="document"
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "assembly_resolutions",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    bglx: "",
                    kgbgmc: "",
                    bzdw: "",
                    bxry: "",
                    cbzt: "",
                    cbsj: "",
                    cbdw: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "30",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped></style>
